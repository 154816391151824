import React from "react"
import SEO from "../components/seo"
import CovidMessage from "../components/covidMessage";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import BlogPageList from "../components/BlogPageList";


const BlogPage = ({data}) => {

    // get articles for a blog by category slug
    let filteredData = (Array.from(data?.posts?.nodes)).filter((item) => {
        for (let i = 0; i < item.categories.nodes.length; i++){
            if (item.categories.nodes[i].slug === "blog-posts"){
                return item;
            }
        }
    });

    return (
        <Layout pageInfo={{pageName: "blog page"}}>
            <SEO title={"Blog"} />
            <CovidMessage></CovidMessage>
            { <BlogPageList data={filteredData} articlePerPage={10}></BlogPageList> }
        </Layout>
    )
}

export const data = graphql`
    query BlogPosts{
        posts: allWpPost {
            nodes {
              categories{
                  nodes{
                      slug
                  }
              }

              id
              title
              content
              blogPostInfo {
                date
                img {
                  mediaItemUrl
                  altText
                }
              }
            }
          }
    }
`

export default BlogPage
