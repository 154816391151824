import React from "react"
import { Component } from "react";

class BlogHeader extends Component{

    render(){
        return(
            <div className="blog-header-wrapper">
                <div className="blog-header">
                    <h2 className="blog-header-title text-primary-dark letter-spacing-n2 font-weight-900 text-center">iCashCars <span className="text-dark">Blog</span></h2>
                    <p className="blog-header-subtitle text-center">Anything and everything that’s going on in the car world…</p>
                </div>
            </div>
        )
    }
}

export default BlogHeader
