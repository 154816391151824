import React from "react"
import { Component } from "react";
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import BlogHeader from "../components/BlogHeader";
class BlogPageList extends Component{

    blogHeader = React.createRef();
    data = this.props.data;
    articlePerPage = this.props.articlePerPage;
    totalPages = Math.ceil(this.data.length / this.articlePerPage);
    maxVisiblePagination = 5;

    state = {
        currentPage: 0,
        firstVisible: 0
    }

    moveToLastPage = () => {
        this.setState({
            currentPage: this.totalPages - 1,
        });

        this.scrollToMyRef();
    };

    moveToFirstPage = () => {
        this.setState({
            currentPage: 0,
        });

        this.scrollToMyRef();
    };

    moveOnePageForward = () => {
        if (this.state.currentPage + 1 >= this.state.firstVisible + this.maxVisiblePagination){

            let maxVisible = this.state.currentPage + this.maxVisiblePagination >= this.totalPages ? this.totalPages - this.maxVisiblePagination : this.state.currentPage + 1;

            this.setState({
                currentPage: this.state.currentPage + 1 >= this.totalPages
                ? this.totalPages - 1
                : this.state.currentPage + 1,
                firstVisible: maxVisible,
            });
        } else {
            this.setState({
                currentPage: this.state.currentPage + 1 >= this.totalPages
                ? this.totalPages - 1
                : this.state.currentPage + 1,
            });
        }

        this.scrollToMyRef();
    };

    moveOnePageBackward = () => {
        if (this.state.currentPage - 1 < this.state.firstVisible){

            let minVisible = this.state.currentPage - this.maxVisiblePagination < 0 ? 0 : this.state.currentPage - this.maxVisiblePagination;

            this.setState({
                currentPage: this.state.currentPage - 1 < 0
                ? 0
                : this.state.currentPage - 1,
                firstVisible: minVisible,
            });
        } else {
            this.setState({
                currentPage: this.state.currentPage - 1 < 0
                ? 0
                : this.state.currentPage - 1,
            });
        }

        this.scrollToMyRef();
    };

    moveToClickedPage = (e) => {
        const target = e.target;
        const selectedPageNumber = parseInt(target.innerText) - 1;

        if (selectedPageNumber !== this.state.currentPage){
            if (selectedPageNumber === this.state.firstVisible){
                let minVisible = selectedPageNumber - 1 < 0 ? 0 : selectedPageNumber - 1;

                this.setState({
                    currentPage: selectedPageNumber,
                    firstVisible: minVisible
                });
            } else if (selectedPageNumber === this.state.firstVisible + this.maxVisiblePagination - 1){
                let maxVisible = selectedPageNumber + 1 === this.totalPages ? this.totalPages - this.maxVisiblePagination : selectedPageNumber + 2 - this.maxVisiblePagination;

                this.setState({
                    currentPage: selectedPageNumber,
                    firstVisible: maxVisible
                });
            } else {
                if (selectedPageNumber === this.totalPages - 1){
                    this.setState({
                        currentPage: selectedPageNumber,
                        firstVisible: this.totalPages - this.maxVisiblePagination
                    });
                } else if (selectedPageNumber === 0 ){
                    this.setState({
                        currentPage: selectedPageNumber,
                        firstVisible: 0
                    });
                } else {
                    this.setState({
                        currentPage: selectedPageNumber
                    });
                }
            }

            this.scrollToMyRef();
        }
    };

    scrollToMyRef = () => this.blogHeader.current.scrollIntoView({behavior: "smooth"});

    DrawPagination(pages){

        let results = [];

        if (pages > 5){
            results.push(this.DrawPaginationButtonPrev())
        }

        if (this.state.firstVisible !== 0){
            if (this.state.firstVisible - 1 > 0){
                results.push(this.DrawPaginationButton(0))
                results.push(this.DrawPaginationButtonDots())
            } else if (this.state.firstVisible - 1 === 0){
                results.push(this.DrawPaginationButton(0))
            }
        }

        let start = this.state.firstVisible >= 0 ? this.state.firstVisible : 0;
        let stop = this.state.firstVisible + this.maxVisiblePagination > this.totalPages ? this.totalPages : this.state.firstVisible + this.maxVisiblePagination;

        for(let i = start; i < stop; i++){
            results.push(this.DrawPaginationButton(i))
        }

        if (this.state.firstVisible + this.maxVisiblePagination < this.totalPages){
            if (this.state.firstVisible + this.maxVisiblePagination === this.totalPages){
                results.push(this.DrawPaginationButton(this.totalPages - 1))
            } else {
                results.push(this.DrawPaginationButtonDots())
                results.push(this.DrawPaginationButton(this.totalPages - 1))
            }
        }

        if (pages > 5){
            results.push(this.DrawPaginationButtonNext())
        }

        return results;
    }

    DrawPaginationButton(pageNumber){
        return (
            <button className="blog-pagination-btn"
                data-page={this.state.currentPage === pageNumber ? true : false}
                onClick={(e) => this.moveToClickedPage(e)}>
                {pageNumber+1}
            </button>
        )
    }

    DrawPaginationButtonPrev(){
        return (
            <div>
                <button className="blog-pagination-btn"
                    onClick={(e) => this.moveOnePageBackward(e)}>
                    {"<<"}
                </button>
            </div>
        )
    }

    DrawPaginationButtonNext(){
        return (
            <div>
                <button className="blog-pagination-btn"
                    onClick={(e) => this.moveOnePageForward(e)}>
                    {">>"}
                </button>
            </div>
        )
    }

    DrawPaginationButtonDots(){
        return (
            <div>
                <button className="blog-pagination-btn blog-pagination-btn-dots">{"..."}</button>
            </div>
        )
    }

    DateConverter(date){
        // date in the format d/m/y from WP
        let components = date.split('/');

        let now = new Date(components[2],components[1],components[0]);
        let day = now.getDate();
        let mounth = now.getMonth();
        let year = now.getFullYear();

        let result = "" + day;

        if (day === 1 || day === 21 || day === 31){
            result += "st";
        }
        else if (day === 2 || day === 22){
            result += "nd";
        }
        else if (day === 3 || day === 22){
            result += "rd";
        }
        else{
            result += "th";
        }

        result += " ";

        const monthName=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        result += monthName[mounth];

        result += ", ";

        result += year

        return result;
    }

    DrawPage(){
        let articles = this.data.slice(this.state.currentPage * this.articlePerPage, (this.state.currentPage + 1) * this.articlePerPage);

        return (
            articles.map((item, index) => {
                let blogReviewWrapper = index === 0 ? "blog-review-wrapper-big" : "blog-review-wrapper-small";
                return (

                    <Link to={`/articles/${item.id}`}
                      state={{ articleTitle: item.title, articleContent: item.content, articleImg: item.blogPostInfo?.img}}
                      className={blogReviewWrapper + " blog-review-link blog-review-wrapper"} >
                        <article className="blog-review">
                            <div className="blog-article-img-wrapper">
                                {<img className="blog-article-img" src={item.blogPostInfo?.img?.mediaItemUrl ?? ""} alt={item.blogPostInfo?.img?.altText ?? ""}/>}
                            </div>
                            <div>
                                <h3 className="text-primary-dark text-right">{item.title}</h3>
                                <p className="text-right blog-post-date">
                                    { this.DateConverter(item.blogPostInfo.date) }
                                </p>
                            </div>
                        </article>
                    </Link>
            )}
            )
        )
    }

    render(){
        return (
            <Container className="mt-6 mb-3 blog-page">

                <div ref={this.blogHeader}>
                    <BlogHeader></BlogHeader>
                </div>

                <div className="blog-article-wrapper">
                    <div className="blog-article-list">
                        { this.DrawPage() }
                    </div>

                    <div className="pagination-wrapper">
                        {this.DrawPagination(this.totalPages)}
                    </div>
                </div>
            </Container>
        )
    }
}

export default BlogPageList
